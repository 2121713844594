<template>
    <div>
        <qy-top-nav title="我的足迹"/>
        <van-dropdown-menu>
            <van-dropdown-item v-model="goodsStatus" :options="goodsStatusList" @change="handleChangeGoodsStatus"/>
            <van-dropdown-item v-model="favoriteTime" :options="favoriteTimeList" @change="handleChangeFavoriteTime"/>
        </van-dropdown-menu>
        <div :style="{height: containerHeight,overflow:'auto'}">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    :offset="offset"
                    finished-text="没有更多了"
                    @load="onLoad"
            >
                <div class="dayHistory" v-for="data in dataList" >
                    <div class="title">{{data.date}}</div>
                    <van-grid :column-num="3"  class="hotGoods" style="padding:5px;">
                        <van-grid-item v-for="goods in data.goodsList" :to="'/goodsInfo?goodsId='+goods.goodsId">
                            <van-image
                                width="100"
                                height="100"
                                :src="getImgUrl(goods.goodsImg)"
                            />
                            <div class="goodsInfo">
                                <span>¥{{goods.wholesalePrice}}</span>
								<i class="van-icon van-icon-arrow van-cell__right-icon"><!----></i>
                            </div>
                        </van-grid-item>
                    </van-grid>
                </div>
            </van-list>
        </div>
    </div>
</template>
<script type="text/javascript">
import request from "@/utils/request";
import { Toast } from 'vant';
export default {
    name: "Footmark",
    components: {},
    data() {
        return {
            loading:false,
            finished:false,
            offset:50,
            containerHeight: 0,
            goodsStatus: "-1",
            goodsStatusList: [
                { text: "全部", value: "-1" },
                { text: "上架中", value: "1" },
                { text: "已下架", value: "0" }
            ],
            favoriteTime: "-1",
            favoriteTimeList: [
                { text: "所有时间", value: "-1" },
                { text: "3天", value: "3" },
                { text: "1星期", value: "7" },
                { text: "1个月", value: "30" }
            ],
            pageSize: 10,
            pageNum: 1,
            dataList:[],
            tempObj:{},
        }
    },
    methods:{
        handleChangeGoodsStatus(v){
            this.goodsStatus = v;
            this.pageNum = 1;
            this.dataList = [];
            this.tempObj = {};
            this.loading =false;
            this.doRequestFootMark();
        },
        handleChangeFavoriteTime(v){
            this.favoriteTime = v;
            this.pageNum = 1;
            this.dataList = [];
            this.tempObj = {};
            this.loading =false;
            this.doRequestFootMark();
        },
        onLoad(){
            //是否已经登录
            let isLogin = this.isLogin();
            if(!isLogin){
                this.$router.push("/login");
                return
            }
            if(!this.finished){
                this.doRequestFootMark();
            }
        },
        doRequestFootMark(){
            let that = this;
            const db = {
                saleStatus:this.goodsStatus,
                browseTime:this.favoriteTime,
                pageNum:this.pageNum,
                pageSize:this.pageSize,
            };
            request.post("/api/browse/list",db).then(
                res => {
                    if(res.success){
                        let list = res.data.list;
                        let hasNextPage = res.data.hasNextPage;
                        list.forEach(function (item,index) {
                            if(that.tempObj.hasOwnProperty(item.browseDate)){
                                that.tempObj[item.browseDate].push({
                                    id:item.id,
                                    goodsId:item.goodsId,
                                    goodsImg:item.goodsLogo,
                                    goodsName:item.goodsName,
                                    goodsDesc:item.goodsRemark,
                                    goodsPrice:item.goodsPrice

                                });
                            }else {
                                that.tempObj[item.browseDate] = [];
                                that.tempObj[item.browseDate].push({
                                    id:item.id,
                                    goodsId:item.goodsId,
                                    goodsImg:item.goodsLogo,
                                    goodsName:item.goodsName,
                                    goodsDesc:item.goodsRemark,
                                    goodsPrice:item.goodsPrice
                                });
                            }
                        });
                        for(let key  in that.tempObj){
                            let date  = key;
                            let array = that.tempObj[key];
                            that.dataList.push({
                                date:date,
                                goodsList:array
                            });
                        }
                        if(!hasNextPage){
                            that.finished = true;
                            that.loading = false;
                        }else {
                            that.finished = false;
                            that.loading = false;
                            that.pageNum++;
                        }

                    }else {
                        that.finished = true;
                    }
                }
            );
        }
    },
    mounted() {
        this.containerHeight = document.documentElement.clientHeight - 100 + "px";
    }

}
</script>
<style type="text/css" scoped="">
.van-grid{padding:5px;}
.listDetails {
    display: flex;
    padding-left: 30px;
    align-items: center;
    margin-top: 10px;
}

.shopImg {
    width: 150px;
    margin: 0 10px;
}

.shopImg img {
    width: 140px;
    height: 140px;
    border-radius: 10px;

}
.van-grid-item{width:33.33%;flex-basis:2px!important;}
.van-cell__right-icon{color: #ccc;
    width: 30px;
    height: 15px;
    float: right;
    text-align: right;
    margin-top: -5px;}
.goodsTitle .p1 {
    font-weight: bold;
}

.goodsTitle .p2 {
    color: #9c9c9c;
    background: #fbfbfb;
    padding: 4px 2px;
    height: 2.2em;
    overflow: hidden;
}
.van-grid-item{margin-bottom:5px!important;}
.title {
    font-size: 16px;
	font-weight:normal;
	padding:15px;
}
.dayHistory img{
	width: 100%;
}
.goodsInfo{
	margin-top: 10px;
	width:100%;
}
.goodsInfo{color:#ff6034;}
</style>